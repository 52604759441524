<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <div v-if="this.$route.name === 'project-preview'">
      <b-button
        v-ripple.400="'rgba(30, 30, 30, 0.15)'"
        variant="outline-dark"
        pill
        @click="$router.push({ name: 'projects' })"
        class="mr-2"
      >
        <feather-icon icon="ArrowLeftIcon" /> Назад
      </b-button>
      <b-button
        v-ripple.400="'rgba(30, 30, 30, 0.15)'"
        variant="outline-primary"
        pill
        @click="
          $router.push({
            name: 'project-edit',
            params: {
              id: $route.params.id,
              name: $route.params.name,
            },
          })
        "
      >
        <feather-icon icon="Edit2Icon" /> Редактировать
      </b-button>
    </div>
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- Bookmarks Container -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <tariff v-if="isMainInstance" />
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BButton } from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import Tariff from "./components/Tariff.vue";

import Ripple from "vue-ripple-directive";

export default {
  components: {
    BLink,
    BButton,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    Tariff,
  },
  directives: {
    Ripple,
  },
  computed: {
    isMainInstance() {
      return !process.env.VUE_APP_ENV || process.env.VUE_APP_ENV !== "white";
    },
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  created() {},
};
</script>
